import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  DatePicker,
  Tag,
  PageHeader,
  Row,
  Col,
  notification,
  Input,
  Modal,
  Select,
  message,
  Icon,
} from "antd";

import axios from "axios";
import moment from "moment";
import endpoint from "../../helpers/Endpoint";
import CustomPopupConfirm from "./CustomPopupConfirm";

const NewRest = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [branchFilter, setBranchFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [invoiceStartDate, setInvoiceStartDate] = useState(null);
  const [invoiceEndDate, setInvoiceEndDate] = useState(null);
  const [amount, setAmount] = useState("");
  const [invoice, setInvoice] = useState("");
  const [addId, setAddId] = useState();
  const [isPopup, setPopup] = useState(false);
const [editedAmount, setEditedAmount] = useState(""); // Store edited amount
const [editedAmounts, setEditedAmounts] = useState({}); // Store amounts per row
  const [processedPayments, setProcessedPayments] = useState(() => {
    return JSON.parse(localStorage.getItem("processedPayments")) || [];
  });

  useEffect(() => {
    let date = new Date();
    let startDate = moment(date).hour(4).minute(0);
    let endDate = moment(startDate).add(1, "days").hour(3).minute(59);
    fetchData(startDate, endDate);
  }, []);

  useEffect(()=>{
    getBranchList();
  },[])

  

  const getBranchList = async () => {
    try {
      let result = await fetch(`${endpoint}/admin/getAllBranches`, {
        method: "Get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((result) => result.json())
        .then((result) => {
          if (result.status === "failure") {
            console.log("THERE IS A FAULURE ");
          }
          if (result) {
            // setBranch(result.data);
            setRestaurants(result.data);
            // setVendors(result.data[1].data);
            // setRetailers(result.data[2].data);
          }
        });
    } catch (error) {
      console.log("something went wrong");
    }
  };

  // Update your useEffect or add this function
const prepareFilters = (data) => {
  // Prepare branch filters
  const uniqueBranches = [...new Set(data.map(item => item.branchName))];
  const branchFilters = uniqueBranches.map(branch => ({
    text: branch,
    value: branch,
  }));
  setBranchFilter(branchFilters);

  // Prepare status filters
  const uniqueStatuses = [...new Set(data.map(item => item.status))];
  const statusFilters = uniqueStatuses.map(status => ({
    text: status,
    value: status,
  }));
  setStatusFilter(statusFilters);
};

  const fetchData = async (startDate, endDate) => {
    setLoading(true);
    const token = localStorage.getItem("token");

    // Convert dates to YYYY-MM-DD format for correct comparison
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

    try {
      const response = await axios.post(
        `${endpoint}/admin/getOppgorsOnly`,
        { startDate, endDate },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
      console.log("response.data", response.data.data);

      setData(response.data.data);
      prepareFilters(response.data.data); // Add this line
      setDateRange({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    } catch (error) {
      notification.error({ message: "Failed to fetch data" });
    }
    setLoading(false);
  };


  const handleAmountChange = (value, _id) => {
    setEditedAmounts((prev) => ({
      ...prev,
      [_id]: value,
    }));
  };

  const showConformationDialog = (id) => {
    setAddId(id);
    setPopup(true);
    //deleteAddvert(id);
    //setPopup(true);
    //deleteApi(id)
  };
  const cancelConformationDialog = () => {
    setPopup(false);
  };



  const deleteApi = async () => {
    console.log("id == ", addId);

    try {
      const res = await axios.post(
        `${endpoint}/api/v1/admin/autoPay/deleteInvoice`,
        { _id: addId }
      );

      if (res.data.status == "success") {
        setPopup(false);
        notification.open({
          message: "Payment deleted successfully!",
          icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 4,
        });
        let date = new Date();
    let startDate = moment(date).hour(4).minute(0);
    let endDate = moment(startDate).add(1, "days").hour(3).minute(59);
    fetchData(startDate, endDate);
      }
    } catch (error) {
      console.log("something went wrong");
      setPopup(false);
    }
  };

  // Save API Call
const handleStatus = async (record) => {

  try {
    const payload = {
      _id: record._id,
      status: "SUCCESS",
      amount: parseFloat(editedAmount),
    };

    const response = await axios.post(
      `${endpoint}/api/v1/admin/autoPay/editStatus`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    );

    if (response.data.status === "success") {
      notification.success({ message: "Status updated successfully" });
    } else {
      notification.error({ message: "Failed to update Status" });
    }
    let date = new Date();
    let startDate = moment(date).hour(4).minute(0);
    let endDate = moment(startDate).add(1, "days").hour(3).minute(59);
    fetchData(startDate, endDate);
  } catch (error) {
    notification.error({ message: "Error updating invoice Status" });
  }
};


  const handleProceedPayment = async (record, savedId = null) => {
    try {
      const payload = {
        branchId: record.branchId,
        amount: record.amount,
        orderId: record._id,
      };
      setModalVisible(false);

      const response = await axios.post(
        `${endpoint}/api/v1/admin/autoPay/manualPayment`,
        payload
      );

      const responseData = response.data.data[0];
      if (responseData.paymentStatus === "AUTHORIZED") {
        notification.success({ message: "Payment processed successfully" });

        const formattedStartDate = moment(dateRange.startDate).format(
          "YYYY-MM-DD"
        );
        const formattedEndDate = moment(dateRange.endDate).format("YYYY-MM-DD");

        const newProcessedPayments = [
          ...processedPayments,
          {
            branchId: record.branchId,
            amount: record.amount,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
        ];

        setProcessedPayments(newProcessedPayments);
        localStorage.setItem(
          "processedPayments",
          JSON.stringify(newProcessedPayments)
        );

        setData((prevData) =>
          prevData.map((item) =>
            item.branchId === record.branchId &&
            item.amount === record.amount &&
            formattedStartDate ===
              moment(dateRange.startDate).format("YYYY-MM-DD") &&
            formattedEndDate === moment(dateRange.endDate).format("YYYY-MM-DD")
              ? { ...item, status: "SUCCESS" }
              : item
          )
        );
      } else {
        notification.error({
          message: "Payment failed",
          description: "Re-attempting with saved ID...",
          duration: 4,
        });
        if (responseData.savedId)
          handleProceedPayment(record, responseData.savedId);
      }
    } catch (error) {
      notification.error({ message: "Failed to process payment", duration: 4 });
      let date = new Date();
      let startDate = moment(date).hour(4).minute(0);
      let endDate = moment(startDate).add(1, "days").hour(3).minute(59);
      fetchData(startDate, endDate);
    }
  };


  const handleProceed = async () => {
    if (!selectedRestaurant || !amount || !invoiceStartDate || !invoiceEndDate || !invoice) {
      message.warning("Please select a restaurant, enter amount, invoice number, and date range.");
      return;
    }
  
    const payload = {
      branchId: selectedRestaurant,
      amount: parseFloat(amount),
      invoiceNo: invoice,
      startDate: moment(invoiceStartDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(invoiceEndDate).format("YYYY-MM-DD HH:mm:ss"),
    };

    console.log("payload", payload);
    // return;
  
    try {
      const response = await axios.post(`${endpoint}/api/v1/admin/autoPay/manualPayment`, payload, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      });

      console.log("response.data.success", response.data)
      console.log("response.data.success", response.data?.data?.[0])
      const responseData = response.data?.data?.[0];
      if (!responseData) {
        notification.error({ message: "Payment failed, please try again!" });
      }
  
      if (responseData.paymentStatus === "AUTHORIZED") {
        notification.success({ message: "Payment processed successfully!" });
        setModalVisible(false);
        setSelectedRestaurant(null);
        setAmount("");
        setInvoice("");
        setInvoiceStartDate(null);
        setInvoiceEndDate(null);
      } else {
        notification.error({ message: "Payment failed, please try again!" });
      }
    } catch (error) {
      console.error("Payment error:", error);
      notification.error({ message: "Failed to process payment" });
      setModalVisible(false);
      let date = new Date();
      let startDate = moment(date).hour(4).minute(0);
      let endDate = moment(startDate).add(1, "days").hour(3).minute(59);
      fetchData(startDate, endDate);
    }
  };
  
  const handleCancel = () => {
    setEditingRow(null);
    setEditedAmount(null);
  };
  const handleEdit = (record) => {
    console.log("record", record)
    setEditingRow(record._id);
    setEditedAmount(record.amount);
  };
  // Save API Call
const handleSave = async (record) => {
  console.log("record", editedAmount, editingRow)
  const newAmount = editedAmount;
  if (!newAmount) {
    notification.warning({ message: "Please enter a valid amount" });
    return;
  }

  try {
    const payload = {
      _id:editingRow,
      amount: parseFloat(newAmount),
    };

    const response = await axios.post(
      `${endpoint}/api/v1/admin/autoPay/editAmount`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    );

    if (response.data.status === "success") {
      notification.success({ message: "Amount updated successfully" });

      setEditingRow(null);
      setEditedAmount(null);
      let date = new Date();
      let startDate = moment(date).hour(4).minute(0);
      let endDate = moment(startDate).add(1, "days").hour(3).minute(59);
      fetchData(startDate, endDate);
    } else {
      notification.error({ message: "Failed to update amount" });
    }
  } catch (error) {
    notification.error({ message: "Error updating invoice amount" });
  }
};

  const columns = [
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
      filters: branchFilter,
    onFilter: (value, record) => record.branchName.includes(value),
    filterSearch: true,
      render: (text, record) => (
        <>
         <b> {record.branchName}</b>    {record.hasToken ? "✅" : ""}
        </>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) =>
        editingRow === record._id ? (
          <Input
            value={editedAmount}
            onChange={(e) => setEditedAmount(e.target.value)}
          />
        ) : (
         <b>Kr. {text}</b>
        ),
        sorter: (a, b) => {
          return a.amount - b.amount;
        },
        sortDirections: ["ascend", "descend"],
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
    },
    {
      title: "Period From",
      key: "period",
      render: (text, record) => `${record.startDate} - ${record.endDate}`,
    },  
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: statusFilter,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Tag
          color={
            status === "SUCCESS"
              ? "green"
              : status === "NotProcessed"
              ? "skyblue"
              : "red"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "edit",
      render: (_, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <button
            className="button is-danger"
            onClick={() => showConformationDialog(record._id)}
            style={{ marginLeft: "8px" }}
          >
            <i className="fa fa-trash" />
          </button>
          {record.status !== "SUCCESS" && (
            <>
              {editingRow === record._id ? (
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Button 
                    type="primary" 
                    onClick={() => handleSave(record)}
                    style={{ margin: 0 }}
                  >
                    Save
                  </Button>
                  <Button 
                    onClick={handleCancel}
                    style={{ margin: 0 }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <button 
                  className="button is-primary" 
                  onClick={() => {
                    setEditingRow(record._id);
                    setEditedAmount(record.amount);
                  }}
                >
                  <i className="fa fa-edit" />
                </button>
              )}
            </>
          )}
        </div>
      ),
    },
    // {
    //   title: "Action",
    //   key: "edit",
    //   render: (_, record) => (
    //     <>
    //     <button
    //       className="button is-danger"
    //       onClick={() => showConformationDialog(record._id)}
    //       style={{ marginLeft: "8px" }}
    //     >
    //       <i className="fa fa-trash" />
    //     </button>
    //     {record.status !== "SUCCESS" && (
    //       <>
    //      { editingRow === record._id ? (
    //       <>
    //         <Button type="primary" onClick={() => handleSave(record._id)}>Save</Button>
    //         <Button  onClick={handleCancel}>Cancel</Button>
    //       </>
    //     ) : (
    //       <button  className="button is-primary" 
    //       onClick={() => {
    //               setEditingRow(record._id);
    //               setEditedAmount(record.amount); // Pre-fill current amount
    //             }}
    //       > <i className="fa fa-edit" /></button>
    //     )}
    //       </>
    //     )}
    //   </>
    //   ),
    // },
      {
      title: "Action",
      key: "proceedPayment",
      render: (_, record) =>
        record.status === "SUCCESS" || (
      
          <Button
          type="primary"
          onClick={() => handleStatus(record)}
          style={{ background: "#389e0d", borderColor: "#389e0d" }}
        >
          Mark as Paid
        </Button>
        ),
    },
    {
      title: "Action",
      key: "proceedPayment",
      render: (_, record) =>
        record.status === "SUCCESS" || record.amount == 0 ? null
       : (
          <Button  className="button is-primary" onClick={() => handleProceedPayment(record)}>
            Proceed Payment
          </Button>
        ),
    },
   
    
  ];

  return (
    <div>
      <PageHeader className="site-page-header oldRestNewRest">
        <Row style={{ width: "100%" }}>
          <Col span={10}>
          </Col>
          <Col span={14}>
            <div className="d-flex" style={{ textAlign: "right" }}>
              <strong className="heading2">
                {moment(dateRange.startDate).format("MMM DD, YYYY")} 04:00 am to{" "}
                {moment(dateRange.endDate).format("MMM DD, YYYY")} 03:59 am
              </strong>
            </div>
            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <DatePicker
                onChange={(e) =>
                  fetchData(
                    moment(e).hour(4).minute(0),
                    moment(e).add(1, "days").hour(3).minute(59)
                  )
                }
              />
            </div>
          </Col>
        </Row>
      </PageHeader>
      <Row>
        <Col>
          <div
            className="d-flex"
            style={{ textAlign: "left", paddingBottom: "10px" }}
          >
            <Button
              onClick={() => {
                setModalVisible(true);
              
              }}
            >
              Custom Pay
            </Button>
          </div>
          <>
            <Modal
              title="Custom Payment"
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <Select
                style={{ width: "100%", marginBottom: 16 }}
                placeholder="Select a Branch"
                onChange={setSelectedRestaurant}
              >
                {restaurants.map((restaurant) => (
                  <Select.Option key={restaurant._id} value={restaurant._id}>
                    <b>{restaurant.name}</b> - {restaurant.branchType}{" "}
                    {restaurant.hasPaymentToken ? "✅" : ""}
                  </Select.Option>
                ))}
              </Select>

              <Input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                style={{ marginBottom: 16 }}
              />
              <Input
                type="string"
                placeholder="Enter Invoice Number"
                value={invoice}
                onChange={(e) => setInvoice(e.target.value)}
                style={{ marginBottom: 16 }}
              />
              <DatePicker
              placeholder="Invoice Start Date"
                onChange={(e) =>
                  setInvoiceStartDate(
                    moment(e).hour(4).minute(0),
                    moment(e).add(1, "days").hour(3).minute(59)
                  )
                }
              />
               <DatePicker
                 placeholder="Invoice End Date"
                onChange={(e) =>
                  setInvoiceEndDate(
                    moment(e).hour(4).minute(0),
                    moment(e).add(1, "days").hour(3).minute(59)
                  )
                }
              />

              <Button
             style={{marginTop:"20px"}}
                type="primary"
                 onClick={handleProceed}
                block
              >
                Proceed
              </Button>
            </Modal>
          </>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="branchId"
      />

<CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        productId={addId}
        action={deleteApi}
      />
    </div>
  );
};

export default NewRest;
