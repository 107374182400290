import React from "react";
import { Card, Button, Spin } from "antd";

const ReserveTableComponent = (props) => {
  let commonStyle = {
    fontSize: "1.5rem",
    fontWeight: "600",
    marginBottom: "20px",
    marginLeft:"30px"
  };
  return (
    <div
      className="site-card-border-less-wrapper"
      style={{
        display: "flex",
        // width: "100vw",
        height: "50vh",
        alignContent: "center",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        borderRadius:10
      }}
    >
      <Card
        title="Reserve Table Price"
        bordered={false}
        style={{
          width: '100%',
        }}
      >
        {props.loading && (
          <div style={{padding:15}}>
            <Spin />
          </div>
        )}
        {props.price.price > -1 && !props.loading ? (
  <h1 style={commonStyle}>
    {props.price.price} <span>NOK</span>
  </h1>
) : (
  !props.loading && <p style={commonStyle}>Please set price</p>
)}


        <div>
          <Button
            type="danger"
            onClick={() => props.onAddPriceHandler(true)}
            style={{ fontWeight: "600" }}
          >
            Add Price
          </Button>
        </div>
      </Card>
    </div>
  );
};
export default ReserveTableComponent;


// // import React,{useState} from "react";
// // import { Card, Button, Spin, Select } from "antd";

// // const ReserveTableComponent = (props) => {
// //   const { Option } = Select;
// //   const [monthsToExpire, setmonthsToExpire] = useState(3);
// //   let options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
// //   let commonStyle = {
// //     fontSize: "1.5rem",
// //     fontWeight: "600",
// //     marginBottom: "20px"
// //   };
// //   return (
// //     <div
// //       className="site-card-border-less-wrapper"
// //       style={{
// //         display: "flex",
// //         // width: "100vw",
// //         height: "50vh",
// //         alignContent: "center",
// //         boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
// //         borderRadius:10
// //       }}
// //     >
// //       <Card
// //         title="Reserve Table Price"
// //         bordered={false}
// //         style={{
// //           width: '100%',
// //         }}
// //       >
// //         {props.loading && (
// //           <div style={{padding:15}}>
// //             <Spin />
// //           </div>
// //         )}
// //         {props.price>-1&& !props.loading && (
// //           <h1 style={commonStyle}>
// //             {props.price}
// //             <span>Kr</span>
// //           </h1>
// //         )}

// //         {!props.loading  && (
// //           <p style={commonStyle}>Please set price</p>
// //         )}

// //         <div>
// //           <Button
// //             type="danger"
// //             onClick={() => props.onAddPriceHandler(true)}
// //             style={{ fontWeight: "600" }}
// //           >
// //             Add Price
// //           </Button>
// //         </div>
// //       </Card>
// //       <Card
// //         title="Reserve Table Coupon Validity in Months"
// //         bordered={false}
// //         style={{
// //           width: '100%',
// //         }}
// //       >
// //         {props.loading && (
// //           <div style={{padding:15}}>
// //             <Spin />
// //           </div>
// //         )}
// //         {props.price>-1&& !props.loading && (
// //           <h1 style={commonStyle}>
// //             {props.price}
// //             <span>Kr</span>
// //           </h1>
// //         )}

// //         <div className="field">
// //                 <div className="control">
// //                   <div style={{ width: "100%" }} className="select">
// //                     <Select
// //                       required
// //                       name="month"
// //                       placeholder="Please select"
// //                       style={{ width: "100%" }}
// //                       defaultValue={3}
// //                       // value={getTaxValues(taxType)}
// //                       onChange={(e) => setmonthsToExpire(e)}
// //                     >
// //                       {options &&
// //                         options.map((item) => {
// //                           return (
// //                             <Option key={item} value={item}>
// //                               {item}
// //                             </Option>
// //                           );
// //                         })}
// //                     </Select>
// //                   </div>
// //                 </div>
// //               </div>

// //         {/* {!props.loading  && (
// //           <p style={commonStyle}>Please set price</p>
// //         )} */}

// //         <div>
// //           <Button
// //             type="danger"
// //             //onClick={() => props.onAddPriceHandler(true)}
// //             style={{ fontWeight: "600" }}
// //           >
// //             Add Months
// //           </Button>
// //         </div>
// //       </Card>
// //       {/* <Card
// //         title="Coupon Extra % age"
// //         bordered={false}
// //         style={{
// //           width: '100%',
// //         }}
// //       >
// //         {props.loading && (
// //           <div style={{padding:15}}>
// //             <Spin />
// //           </div>
// //         )}
// //         {props.price>-1&& !props.loading && (
// //           <h1 style={commonStyle}>
// //             {props.extraCouponPercent}% age
// //           </h1>
// //         )}

// //         {!props.loading  && (
// //           <p style={commonStyle}>Please set percentage</p>
// //         )}

// //         <div>
// //           <Button
// //             type="danger"
// //             onClick={() => props.onAddPriceHandler(true)}
// //             style={{ fontWeight: "600" }}
// //           >
// //             Add % age
// //           </Button>
// //         </div>
// //       </Card> */}
// //     </div>
// //   );
// // };
// // export default ReserveTableComponent;


// import React from "react";
// import { Card, Button, Spin } from "antd";

// const ReserveTableComponent = (props) => {
//   let commonStyle = {
//     fontSize: "1.5rem",
//     fontWeight: "600",
//     marginBottom: "20px"
//   };
//   return (
//     <div
//       className="site-card-border-less-wrapper"
//       style={{
//         display: "flex",
//         // width: "100vw",
//         height: "50vh",
//         alignContent: "center",
//         boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//         borderRadius:10
//       }}
//     >
//       <Card
//         title="Reserve Table Price"
//         bordered={false}
//         style={{
//           width: '100%',
//         }}
//       >
//         {props.loading && (
//           <div style={{padding:15}}>
//             <Spin />
//           </div>
//         )}
//         {props.price>-1&& !props.loading && (
//           <h1 style={commonStyle}>
//             {props.price}
//             <span>Kr</span>
//           </h1>
//         )}

//         {!props.loading  && (
//           <p style={commonStyle}>Please set price</p>
//         )}

//         <div>
//           <Button
//             type="danger"
//             onClick={() => props.onAddPriceHandler(true)}
//             style={{ fontWeight: "600" }}
//           >
//             Add Price
//           </Button>
//         </div>
//       </Card>
//       <Card
//         title="Coupon Extra % age"
//         bordered={false}
//         style={{
//           width: '100%',
//         }}
//       >
//         {props.loading && (
//           <div style={{padding:15}}>
//             <Spin />
//           </div>
//         )}
//         {props.price>-1&& !props.loading && (
//           <h1 style={commonStyle}>
//             {props.extraCouponPercent}% age
//           </h1>
//         )}

//         {!props.loading  && (
//           <p style={commonStyle}>Please set percentage</p>
//         )}

//         <div>
//           <Button
//             type="danger"
//             onClick={() => props.onAddPriceHandler(true)}
//             style={{ fontWeight: "600" }}
//           >
//             Add % age
//           </Button>
//         </div>
//       </Card>
//     </div>
//   );
// };
// export default ReserveTableComponent;

