import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Login from "./components/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import AddRestaurant from "./components/addRestaurant/AddRestaurant";
import RestaurantList from "./components/restaurantList/RestaurantList";
import ListDevice from "./components/devices/ListDevice";
import AddIcons from "./components/addIcons/AddIcons";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import Oppgjør from "./components/oppgjør/Oppgjør";
import Oppgjørr from "./components/oppgjør-2/Oppgjør";
import OppgjørrOld from "./components/oppgjørOld/OppgjørrOld";
import DepositHistory from "./components/depositHistory/DepositHistory"
import restReports from "./components/restReport/RestReport";
import AdminReports from "./components/adminReports/adminReports";
import AdminWeeklyCancelledReports from "./components/adminReports/adminReportsWeeklyCancelled";
import TaxesComponent from "./components/restaurant/taxes/taxes";
import VerifoneComponent from "./components/restaurant/verifone/verifone";
import ReserveTablePrice from "./components/ReserveTable/index";

import Error404 from "./components/error/Error";
import endpoint from "./helpers/Endpoint";
import Notification from "react-bulma-notification";

import ListAdminIcons from "./components/adminIcons/ListAdminIcons";
import AddAdminIcons from "./components/adminIcons/AddAdminIcons";
import UpdateAdminIcons from "./components/adminIcons/UpdateAdminIcons";
import SuperCategories from "./components/restaurant/superCategories";
import "bulma/css/bulma.css";
import "./App.css";
import index from "./components/Coupons/Index";
import InProcessInvoices from "./components/InProcessInvoices/index";
import LoginDetails from "./components/loginDetails/LoginDetails";
import CouponDetails from "./components/Coupons/CouponDetails";
import OppgjørTables from "./components/oppgjørTables/OppgjørTables";
import AdminPayment from "./components/adminPayment/AdminPayment";
import VendorOppgjr1 from "./components/vendor/Oppgjr1"
import OppgjrIsNew from "./components/vendor/OppgjrIsNew";
import RetailerOppgjr1 from './components/retailer/Oppgjr1';
import OppgjrIsNew2 from "./components/retailer/OppgjrIsNew";
import CloudPaymentRecords from "./components/cloudPaymentRecords/CloudPaymentRecords";
import Advertisements from "./components/advertisements/Advertisements";
import ListIcons from "./components/addIcons/ListIcons";
import VendorIcons from "./components/addIcons/VendorIcons";
import BarberIcons from "./components/addIcons/BarberIcons";
import TFLRange from "./components/Coupons/TFLRange";
import Invitations from "./components/invitations";
import employeePayments from "./components/employeePayments";
import OldRest from "./components/employeePayments/OldRest";
import NewRest from "./components/employeePayments/NewRest";





class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      style: "",
      oPassword: "",
      nPassword: ""
    };
  }

  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  componentDidMount = () => {
    let loggedIn = localStorage.getItem("loggedIn");
    loggedIn = JSON.parse(loggedIn);
    this.setState({ loggedIn });
  };

  componentDidUpdate = () => {
    console.log("test");
  };

  login = (e, email, password) => {
    e.preventDefault();
    const data = JSON.stringify({ email, password });
    // function handleErrors(result) {
    //   if (!result.ok) {
    //     throw Error(result.status);
    //   }
    //   return result;
    // }
    fetch(`${endpoint}/admin/login`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json"
      }
    })
      // .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("errors");
          this.openNotification("error", result.data);
        } else {
          const token = result.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("loggedIn", true);
          this.setState({ loggedIn: true });
          this.props.history.push("/");
          this.openNotification("success", result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  logOut = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("errors");
          localStorage.clear();
          this.setState({ loggedIn: false });
        } else {
          localStorage.clear();
          this.setState({ loggedIn: false });
        }
      })
      .catch((error) => console.log(error));
  };

  changePasswordDialogue = () => {
    this.setState({ style: "is-active" });
  };

  closeModal = () => {
    this.setState({ style: "" });
  };

  changePassword = (e) => {
    e.preventDefault();
    const { oPassword, nPassword } = this.state;
    const data = JSON.stringify({ oPassword, nPassword });
    console.log(data);
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/change-password`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result.message);
          this.openNotification("error", result.message);
        } else {
          console.log(result);
          this.openNotification("success", result.message);
          this.setState({ style: "", oPassword: "", nPassword: "" });
        }
      })
      .catch((error) => console.log(error));
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { loggedIn, style, oPassword, nPassword } = this.state;
    return (
      <Fragment>
        {loggedIn ? (
          <Dashboard
            logOut={this.logOut}
            changePassword={this.changePasswordDialogue}
          />
        ) : (
          <Fragment>
            <Header loggedIn={loggedIn} />
            <Login
              login={(e, email, password) => this.login(e, email, password)}
            />
          </Fragment>
        )}
        {loggedIn ? <>
        <div className="container is-widescreen">
          <div className="columns is-centered is-3">
            <BrowserRouter>
              <Fragment>
                <div className="column is-one-fifth">
                  {loggedIn ? <Sidebar /> : ""}
                </div>
                <div className="column">
                  <Switch>
                    <ProtectedRoute exact path="/" component={RestaurantList} />
                    <ProtectedRoute
                      path="/add-restaurant"
                      component={AddRestaurant}
                    />
                    <ProtectedRoute path="/add-icons" component={AddIcons} />
                    <ProtectedRoute path="/list-icons" component={ListIcons} />
                    <ProtectedRoute path="/vendor-icons" component={VendorIcons} />
                    <ProtectedRoute path="/barber-icons" component={BarberIcons} />

                    <ProtectedRoute
                      path="/adminIcons/add"
                      component={AddAdminIcons}
                    />
                    <ProtectedRoute
                      path="/adminIcons/list"
                      component={ListAdminIcons}
                    />
                    <ProtectedRoute
                      path="/adminIcons/:id/update"
                      component={UpdateAdminIcons}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/oppgjør"
                      component={Oppgjør}
                      exact={true}
                    />

                    <ProtectedRoute
                      path="/oppgjør-2"
                      component={Oppgjørr}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/oppgjørOld"
                      component={OppgjørrOld}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/vendoroppgjør1"
                      component={VendorOppgjr1}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/vendorOppgjrNew"
                      component={OppgjrIsNew}
                      exact={true}
                    />
                     <ProtectedRoute
                      path="/retaileroppgjør1"
                      component={RetailerOppgjr1}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/retailerOppgjrNew"
                      component={OppgjrIsNew2}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/depositHistory"
                      component={DepositHistory}
                      exact={true}
                    />

                    <ProtectedRoute
                      path="/oppgjør-tables"
                      component={OppgjørTables}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/admin-payment"
                      component={AdminPayment}
                      exact={true}
                    />

                    <ProtectedRoute
                      path="/adminreports"
                      component={AdminReports}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/adminreportsweeklycancelled"
                      component={AdminWeeklyCancelledReports}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/reservetable"
                      component={ReserveTablePrice}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/restreports/:id"
                      component={restReports}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/devices"
                      component={ListDevice}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/super-categories/:slug"
                      component={SuperCategories}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/taxes/:slug"
                      component={TaxesComponent}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/machineconfig/:slug"
                      component={VerifoneComponent}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/coupons"
                      component={index}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/inprocessinvoices"
                      component={InProcessInvoices}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/logindetails"
                      component={LoginDetails}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/coupon-detail"
                      component={CouponDetails}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/cloudpaymentrecords"
                      component={CloudPaymentRecords}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/advertisements"
                      component={Advertisements}
                      exact={true}
                    />
                     <ProtectedRoute
                      path="/TFLRange"
                      component={TFLRange}
                      exact={true}
                    />
                    <ProtectedRoute
                      path="/invitations"
                      component={Invitations}
                      exact={true}
                    />
                     <ProtectedRoute
                      path="/payments"
                      component={employeePayments}
                      exact={true}
                    />
                     <ProtectedRoute
                      path="/OldRestuarant"
                      component={OldRest}
                      exact={true}
                    />
                     <ProtectedRoute
                      path="/NewRestaurant"
                      component={NewRest}
                      exact={true}
                    />

                    <ProtectedRoute component={Error404} />
                  </Switch>
                </div>
              </Fragment>
            </BrowserRouter>
          </div>
        </div>
        <div className={`modal ${style}`}>
          <div className="modal-background" />
          <div className="modal-content" style={{ zIndex: 1 }}>
            <div className="tile is-parent">
              <article className="tile is-child notification ">
                <div className="content">
                  <div className="file">
                    <form onSubmit={this.changePassword}>
                      <div className="field">
                        <label className="label">Current Password</label>
                        <div className="control">
                          <input
                            className="input"
                            type="password"
                            name="oPassword"
                            placeholder="Current Password"
                            value={oPassword}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">New Password</label>
                        <div className="control">
                          <input
                            className="input"
                            type="password"
                            name="nPassword"
                            placeholder="New Password"
                            value={nPassword}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="control">
                        <button className="button is-primary">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div
            onClick={this.closeModal}
            style={{
              minWidth: "100%",
              minHeight: "100%",
              position: "absolute"
            }}
          >
            <button className="modal-close is-large" aria-label="close" />
          </div>
        </div>
        </>:""}
      </Fragment>
    );
  }
}

class ProtectedRoute extends Component {
  render() {
    const loggedIn = localStorage.getItem("loggedIn");
    let authenticated = JSON.parse(loggedIn);
    // if (localStorage.getItem('loggedIn') === 'true') {
    //   authenticated = true;
    // } else {
    //   authenticated = false;
    // }
    const { component: Component, ...props } = this.props;

    return (
      <Route
        {...props}
        render={(props) =>
          authenticated ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    );
  }
}

export default App;
